export const PRICE_PER_NFT_VALUE_SET = 'PRICE_PER_NFT_VALUE_SET';

export const MINT_NFT_DIALOG_SHOW = 'MINT_NFT_DIALOG_SHOW';
export const MINT_NFT_DIALOG_HIDE = 'MINT_NFT_DIALOG_HIDE';

export const DEPOSIT_DIALOG_SHOW = 'DEPOSIT_DIALOG_SHOW';
export const DEPOSIT_DIALOG_HIDE = 'DEPOSIT_DIALOG_HIDE';
export const DEPOSIT_CONFIRM_SET = 'DEPOSIT_CONFIRM_SET';

export const WITHDRAW_DIALOG_SHOW = 'WITHDRAW_DIALOG_SHOW';
export const WITHDRAW_DIALOG_HIDE = 'WITHDRAW_DIALOG_HIDE';
export const WITHDRAW_CONFIRM_SET = 'WITHDRAW_CONFIRM_SET';

export const AMOUNT_VALUE_SET = 'AMOUNT_VALUE_SET';

export const SIDE_PANEL_SHOW = 'SIDE_PANEL_SHOW';
export const SIDE_PANEL_HIDE = 'SIDE_PANEL_HIDE';

export const TRANSACTIONS_FETCH_IN_PROGRESS = 'TRANSACTIONS_FETCH_IN_PROGRESS';
export const TRANSACTIONS_FETCH_SUCCESS = 'TRANSACTIONS_FETCH_SUCCESS';
export const TRANSACTIONS_FETCH_ERROR = 'TRANSACTIONS_FETCH_ERROR';

export const TRANSFERS_FETCH_IN_PROGRESS = 'TRANSFERS_FETCH_IN_PROGRESS';
export const TRANSFERS_FETCH_SUCCESS = 'TRANSFERS_FETCH_SUCCESS';
export const TRANSFERS_FETCH_ERROR = 'TRANSFERS_FETCH_ERROR';

export const DISCLAIMER_DIALOG_SHOW = 'DISCLAIMER_DIALOG_SHOW';
export const DISCLAIMER_DIALOG_HIDE = 'DISCLAIMER_DIALOG_HIDE';

export const SALE_TYPE_SET = 'SALE_TYPE_SET';
export const SALE_IN_PROGRESS_SET = 'SALE_IN_PROGRESS_SET';
