import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { ReactComponent as TwitterLogo } from '../../assets/socialLinks/twitter.svg';
import { socialLinks } from '../../config';
import DotsLoading from '../../components/DotsLoading';

const Section2 = (props) => {
    return (
        <div className="section2">
            {props.projectInProgress && !props.project.name
                ? <DotsLoading/>
                : <>
                    <h2>{props.project && props.project.name}</h2>
                    <p>{props.project && props.project.description}</p>
                </>}
            <div className="social_links">
                <a href={socialLinks.TWITTER} rel="noopener noreferrer" target="_blank">
                    <IconButton>
                        <TwitterLogo/>
                    </IconButton>
                </a>
            </div>
        </div>
    );
};

Section2.propTypes = {
    lang: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    projectInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        project: state.mint.project.value,
        projectInProgress: state.mint.project.inProgress,
    };
};

export default connect(stateToProps)(Section2);
