import { combineReducers } from 'redux';
import {
    AMOUNT_VALUE_SET,
    DEPOSIT_CONFIRM_SET,
    DEPOSIT_DIALOG_HIDE,
    DEPOSIT_DIALOG_SHOW,
    DISCLAIMER_DIALOG_HIDE,
    DISCLAIMER_DIALOG_SHOW,
    MINT_NFT_DIALOG_HIDE,
    MINT_NFT_DIALOG_SHOW,
    PRICE_PER_NFT_VALUE_SET,
    SALE_IN_PROGRESS_SET,
    SALE_TYPE_SET,
    SIDE_PANEL_HIDE,
    SIDE_PANEL_SHOW,
    TRANSACTIONS_FETCH_ERROR,
    TRANSACTIONS_FETCH_IN_PROGRESS,
    TRANSACTIONS_FETCH_SUCCESS,
    TRANSFERS_FETCH_ERROR,
    TRANSFERS_FETCH_IN_PROGRESS,
    TRANSFERS_FETCH_SUCCESS,
    WITHDRAW_CONFIRM_SET,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../constants/home';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';

export const pricePerNFTValue = (state = '', action) => {
    switch (action.type) {
    case PRICE_PER_NFT_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

const mintNFTDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case MINT_NFT_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const depositDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DEPOSIT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DEPOSIT_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const withDrawDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case WITHDRAW_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case WITHDRAW_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const amountValue = (state = '', action) => {
    switch (action.type) {
    case AMOUNT_VALUE_SET:
        return action.value;
    case DEPOSIT_DIALOG_HIDE:
    case WITHDRAW_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const depositConfirm = (state = false, action) => {
    if (action.type === DEPOSIT_CONFIRM_SET) {
        return action.value;
    } else if (action.type === DEPOSIT_DIALOG_HIDE) {
        return false;
    } else {
        return state;
    }
};

const withdrawConfirm = (state = false, action) => {
    if (action.type === WITHDRAW_CONFIRM_SET) {
        return action.value;
    } else if (action.type === WITHDRAW_DIALOG_HIDE) {
        return false;
    } else {
        return state;
    }
};

const sidePanel = (state = false, action) => {
    switch (action.type) {
    case SIDE_PANEL_SHOW:
        return true;
    case SIDE_PANEL_HIDE:
        return false;
    default:
        return state;
    }
};

const transactions = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case TRANSACTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TRANSACTIONS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP && action.limit === DEFAULT_LIMIT) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case TRANSACTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const disclaimerDialog = (state = false, action) => {
    switch (action.type) {
    case DISCLAIMER_DIALOG_SHOW:
        return true;
    case DISCLAIMER_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const saleType = (state = 'public', action) => {
    switch (action.type) {
    case SALE_TYPE_SET:
        return action.value;
    default:
        return state;
    }
};

const saleInProgress = (state = false, action) => {
    switch (action.type) {
    case SALE_IN_PROGRESS_SET:
        return action.value;
    default:
        return state;
    }
};

const transfers = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case TRANSFERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TRANSFERS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP && action.limit === DEFAULT_LIMIT) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case TRANSFERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    pricePerNFTValue,
    mintNFTDialog,
    depositDialog,
    withDrawDialog,
    amountValue,
    depositConfirm,
    withdrawConfirm,
    sidePanel,
    transactions,
    disclaimerDialog,
    saleType,
    saleInProgress,
    transfers,
});
