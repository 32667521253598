import {
    AMOUNT_VALUE_SET,
    DEPOSIT_CONFIRM_SET,
    DEPOSIT_DIALOG_HIDE,
    DEPOSIT_DIALOG_SHOW,
    DISCLAIMER_DIALOG_HIDE,
    DISCLAIMER_DIALOG_SHOW,
    MINT_NFT_DIALOG_HIDE,
    MINT_NFT_DIALOG_SHOW,
    PRICE_PER_NFT_VALUE_SET,
    SALE_IN_PROGRESS_SET,
    SALE_TYPE_SET,
    SIDE_PANEL_HIDE,
    SIDE_PANEL_SHOW,
    TRANSACTIONS_FETCH_ERROR,
    TRANSACTIONS_FETCH_IN_PROGRESS,
    TRANSACTIONS_FETCH_SUCCESS,
    TRANSFERS_FETCH_ERROR,
    TRANSFERS_FETCH_IN_PROGRESS,
    TRANSFERS_FETCH_SUCCESS,
    WITHDRAW_CONFIRM_SET,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../constants/home';
import Axios from 'axios';
import { urlFetchTransactions, urlFetchTransfers } from '../constants/url';
import { config } from '../config';

export const setPricePerNFT = (value) => {
    return {
        type: PRICE_PER_NFT_VALUE_SET,
        value,
    };
};

export const showMintNFTDialog = (value) => {
    return {
        type: MINT_NFT_DIALOG_SHOW,
        value,
    };
};

export const hideMintNFTDialog = () => {
    return {
        type: MINT_NFT_DIALOG_HIDE,
    };
};

export const showDepositDialog = (value) => {
    return {
        type: DEPOSIT_DIALOG_SHOW,
        value,
    };
};

export const hideDepositDialog = () => {
    return {
        type: DEPOSIT_DIALOG_HIDE,
    };
};

export const setDepositConfirm = (value) => {
    return {
        type: DEPOSIT_CONFIRM_SET,
        value,
    };
};

export const showWithdrawDialog = (value) => {
    return {
        type: WITHDRAW_DIALOG_SHOW,
        value,
    };
};

export const hideWithdrawDialog = () => {
    return {
        type: WITHDRAW_DIALOG_HIDE,
    };
};

export const setWithdrawConfirm = (value) => {
    return {
        type: WITHDRAW_CONFIRM_SET,
        value,
    };
};

export const setAmountValue = (value) => {
    return {
        type: AMOUNT_VALUE_SET,
        value,
    };
};

export const showSidePanel = () => {
    return {
        type: SIDE_PANEL_SHOW,
    };
};

export const hideSidePanel = () => {
    return {
        type: SIDE_PANEL_HIDE,
    };
};

const fetchTransactionsInProgress = () => {
    return {
        type: TRANSACTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchTransactionsSuccess = (value, skip, limit, total) => {
    return {
        type: TRANSACTIONS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchTransactionsError = (message) => {
    return {
        type: TRANSACTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchTransactions = (skip, limit) => (dispatch) => {
    dispatch(fetchTransactionsInProgress());

    const URL = urlFetchTransactions(skip, limit);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
        },
    })
        .then((res) => {
            dispatch(fetchTransactionsSuccess(res && res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
        })
        .catch((error) => {
            dispatch(fetchTransactionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const showDisclaimerDialog = () => {
    return {
        type: DISCLAIMER_DIALOG_SHOW,
    };
};

export const hideDisclaimerDialog = () => {
    return {
        type: DISCLAIMER_DIALOG_HIDE,
    };
};

export const setSaleType = (value) => {
    return {
        type: SALE_TYPE_SET,
        value,
    };
};

export const setSaleInProgress = (value) => {
    return {
        type: SALE_IN_PROGRESS_SET,
        value,
    };
};

const fetchTransfersInProgress = () => {
    return {
        type: TRANSFERS_FETCH_IN_PROGRESS,
    };
};

const fetchTransfersSuccess = (value, skip, limit, total) => {
    return {
        type: TRANSFERS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchTransfersError = (message) => {
    return {
        type: TRANSFERS_FETCH_ERROR,
        message,
    };
};

export const fetchTransfers = (skip, limit) => (dispatch) => {
    dispatch(fetchTransfersInProgress());

    const URL = urlFetchTransfers(skip, limit);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
        },
    })
        .then((res) => {
            dispatch(fetchTransfersSuccess(res && res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
        })
        .catch((error) => {
            dispatch(fetchTransfersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
